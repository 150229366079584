<template>
  <v-scale-transition
    mode="out-in"
  >
    <div
      v-if="isAuthenticated"
      class="light-theme pb-10 d-flex justify-center d-lg-block"
    >
      <notification
        v-if="errorNotification"
        type="error"
      >
        Щось пішло не так. Спробуйте ще раз
      </notification>

      <link-bank-card-form
        :redirect-after-adding-card="userBankCards"
        class="col-sm-10 col-md-9 col-lg-10 col-xl-9 pa-0 mt-5"
      />
    </div>
  </v-scale-transition>
</template>

<script>
import { mapGetters } from 'vuex';
import LinkBankCardForm from '@/views/link-bank-card/LinkBankCardForm';
import Notification from '@/components/common/Notification';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import RouteNamesService from '@/services/route-names-service';
import pageLoaderMixin from '../mixins/pageLoaderMixin';

export default {
  name: 'Index',
  title: 'Прив\'язка картки',
  components: {
    LinkBankCardForm,
    Notification,
  },
  mixins: [blockPreloaderMixin, errorNotificationMixin, pageLoaderMixin],
  data() {
    return {
      userBankCards: RouteNamesService.userBankCards,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
  },
  mounted() {
    this.hidePagePreloader();
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  min-height: auto !important;
}
</style>
